<!-- 培训新生注册 -->
<template>
    <div class="TrainNewborn">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" label-width="120px">
            <el-form-item label="日期查询">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.dateQuery"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="日期类型">
                <el-select v-model="form.dateType" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.dateTypeOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.stateOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="支付方式">
                <el-select v-model="form.payment" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.paymentOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="学科类">
                <el-select v-model="form.subject" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.subjectOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
        </el-form>
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
                <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                    @click="newlyAdded">
                    新增
                </el-button>
                 <!-- <button class="print"  @click="print">打印预览</button> -->
                 <!-- <button class="derive" @click="derive">导出到Excel</button> -->
                 <button class="bill" @click="bill">明细表</button>
            </div>
            <div class="" style="margin-right: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="reset">
                    重置
                </el-button>
            </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" >
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="registration" sortable="custom" label="报名日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="number" sortable="custom" label="报名单号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="peyment_date" sortable="custom" label="交费日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="train_status" sortable="custom" label="入学类型" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.train_status==1">开班入学</span>
                    <span v-else-if="scope.row.train_status==2">插班入学</span>
                    <span v-else-if="scope.row.train_status==3">转班入学</span>
                    <span v-else-if="scope.row.train_status==4">结业1</span>
                    <span v-else-if="scope.row.train_status==5">结业2</span>
                    <span v-else-if="scope.row.train_status==6">结业</span>
                    <span v-else-if="scope.row.train_status==7">退学</span>
                </template>
            </el-table-column>
            <el-table-column prop="student_name" sortable="custom"="custom"  label="学员姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="mobile" sortable="custom" label="学生联系电话" align="center" width="150">
            </el-table-column>
            <el-table-column prop="pinyin_name" sortable="custom" label="拼音名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course_style2" sortable="custom" label="学科类" align="center" width="100">
            </el-table-column>
            <el-table-column prop="class_number" sortable="custom" label="班级编码" align="center" width="120">
            </el-table-column>
            <el-table-column prop="class_name" sortable="custom" label="班级名称" align="center" width="200">
            </el-table-column>
            <el-table-column prop="class_arrangement" sortable="custom" label="授课安排" align="center" width="150">
            </el-table-column>
            <el-table-column prop="bz_lecture_number" sortable="custom" label="授课次数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="bz_tuition_incidentals" sortable="custom" label="标准学杂费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="bz_tuition" sortable="custom" label="其中学费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="bz_incidentals" sortable="custom" label="杂费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="bm_lecture_number" sortable="custom" label="报名次数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="before_discount" sortable="custom" label="优惠前应收" align="center" width="120">
            </el-table-column>
            <el-table-column prop="discount_ratio" sortable="custom" label="优惠率(%)" align="center" width="120">
            </el-table-column>
            <el-table-column prop="discount_price" sortable="custom" label="让利金额" align="center" width="120">
            </el-table-column>
            <el-table-column prop="tuition_incidentals" sortable="custom" label="应收学杂费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="tuition" sortable="custom" label="学费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="incidentals" sortable="custom" label="杂费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="amount_price" sortable="custom" label="应收金额" align="center" width="120">
            </el-table-column>
            <el-table-column prop="transfer_tuition" sortable="custom" label="转入学费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="deposit" sortable="custom" label="定金" align="center" width="120">
            </el-table-column>
            <el-table-column prop="pay_type" sortable="custom" label="支付方式" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.pay_type==1">现金</span>
                    <span v-else-if="scope.row.pay_type==2">银行转账</span>
                    <span v-else-if="scope.row.pay_type==3">支票</span>
                    <span v-else-if="scope.row.pay_type==4">信用卡</span>
                    <span v-else-if="scope.row.pay_type==5">借记卡</span>
                    <span v-else-if="scope.row.pay_type==6">移动支付</span>
                </template>
            </el-table-column>
            <el-table-column prop="refund_amount" sortable="custom" label="退费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="drop_out" sortable="custom" label="退费日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="resources" sortable="custom" label="培训教师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course" sortable="custom" label="学科" align="center" width="200">
            </el-table-column>
            <el-table-column prop="classroom" sortable="custom" label="上课教室" align="center" width="120">
            </el-table-column>
            <el-table-column prop="start_time" sortable="custom" label="开班日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="end_time" sortable="custom" label="结业日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="handle" sortable="custom" label="下单人" align="center" width="120">
            </el-table-column>
            <el-table-column prop="remark" sortable="custom" label="报名备注" align="center" width="120">
            </el-table-column>
            <el-table-column prop="student_card" sortable="custom" label="学生卡号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="add_name" sortable="custom" label="录入人" align="center" width="120">
            </el-table-column>
            <el-table-column prop="add_time" sortable="custom" label="录入时间" align="center" width="200">
            </el-table-column>
            <el-table-column prop="update_name" sortable="custom" label="修改人" align="center" width="100">
            </el-table-column>
            <el-table-column prop="update_time" sortable="custom" label="最近修改时间" align="center" width="200">
            </el-table-column>
            <el-table-column  label="操作" align="center" fixed="right" width="280">
                <template slot-scope="scope">
                    <el-button class="stu" size="mini" @click="Classmate(scope.$index, scope.row)">
                        学员
                    </el-button>
                    <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;" 
                     @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button  icon="el-icon-delete" size="mini" style="background:#E74C3C;color: #fff;"
                     @click="delt(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="" style="display: flex; justify-content: space-between;">
            <!-- <div class="" style="margin-left: 22px;">
                <div class="txtbox">
                    授课老师 <input type="text" class="itp" :value="teacher" disabled="disabled"/>
                </div>
                <div class="txtbox">
                    班级名称 <input type="text" class="itp" :value="className" disabled="disabled" style="width: 217px;"/>
                </div>
                <div class="txtbox">
                    上课地点 <input type="text" class="itp" :value="locale" disabled="disabled" />
                </div>
                <div class="txtbox">
                    课时安排 <input type="text" class="itp" :value="arrange" disabled="disabled" />
                </div>
            </div> -->
            <!--分页 -->
            <el-pagination @size-change="handleSizeChange"
             @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
        
        <!-- 打印预览 -->
        <el-dialog width="900px" :visible.sync="dialog">
            <div id="printSignup">
                <!-- 头部 -->
                <div class="top">
                    <div class="top-logo">
                        <img :src="printData.top_logo">
                    </div>
                    <div class="top-title">
                        培训报名表
                    </div>
                </div>
                <!-- 编号 -->
                <div class="" style="text-align: right;">
                    <div class="" style="margin-right: 40px;font-size: 16px;">
                        No:<span style="padding-left: 10px;">{{ printData.number }}</span>
                    </div>
                </div>
                <!-- 表格 -->
                <table border="1" cellspacing="0" cellpadding="0">
                    <!-- 1 -->
                    <tr>
                        <td class="td_1" rowspan="5"><img :src="printData.head_img"></td>
                        <td>所属地区</td>
                        <td colspan="3"> {{ printData.region }} </td>
                    </tr>
                    <!-- 2 -->
                    <tr>
                        <td>姓名</td>
                        <td> {{ printData.name }} </td>
                        <td class="td_4">性别</td>
                        <td class="td_5"> {{ printData.sex }} </td>
                    </tr>
                    <!-- 3 -->
                    <tr>
                        <td>拼音名</td>
                        <td> {{ printData.pinyin }} </td>
                        <td class="td_4">国籍</td>
                        <td class="td_5"> {{ printData.nationality }} </td>
                    </tr>
                    <!-- 4 -->
                    <tr>
                        <td>培训课名称</td>
                        <td class="td_2"> {{ printData.courseName }} </td>
                        <td class="td_4">民族</td>
                        <td class="td_5"> {{ printData.nation }} </td>
                    </tr>
                    <!-- 5-->
                    <tr>
                        <td>上课教室</td>
                        <td> {{ printData.classroom }} </td>
                        <td class="td_4">授课老师</td>
                        <td class="td_5"> {{ printData.teacher }} </td>
                    </tr>
                    <!-- 6 -->
                    <tr>
                        <td>家庭住址</td>
                        <td colspan="2"> {{ printData.address }} </td>
                        <td class="td_4">出生日期</td>
                        <td class="td_5"> {{ printData.birthday }} </td>
                    </tr>
                    <!-- 7 -->
                    <tr>
                        <td>手机号码</td>
                        <td colspan="2"> {{ printData.telephone }} </td>
                        <td class="td_4">邮编</td>
                        <td> {{ printData.zipCode }} </td>
                    </tr>
                    <!-- 8 -->
                    <tr>
                        <td>授课安排</td>
                        <td colspan="2" class="td_3"> 开班日期:{{ printData.start }},结业日期:{{ printData.end }} </td>
                        <td class="td_4">电话</td>
                        <td class="td_5"> {{ printData.phone }} </td>
                    </tr>
                    <!-- 9 -->
                    <tr>
                        <td>备注</td>
                        <td colspan="4" class="remarks">
                            一、本表系录入书画院学员数据库的依据，请考生务必用正楷字如实填写。因填表错误而造成<br/>
资料有误，将可能会造成培训的延误;<br/>
二、一经报名，原则上按课表上课，若确认上课不能签到，请事先请假，以利于安排补课;<br/>
三、因学员本人原因，造成培训课因故不来，并不告知我院，我院原则上不另行安排补课。</td>
                    </tr>
                </table>
                <!-- 打印预览底部 -->
                <div class="bottom">
                    <!-- 培训课经办人(签章) -->
                    <div class="handle">
                        培训课经办人(签章)：<input type="text" />
                    </div>
                    <div class="bottom_text">
                        <p>深圳书画艺术学院考级办公室</p>
                    </div>
                    <div class="phone_date">
                        <span>电话：0755-8883 0206</span>
                        <span>{{ printData.specificDate }}</span>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog=false">取 消</el-button>
                <el-button style="background: #18BC9C;color: #fff;"
                    v-print="'#printSignup'">
                    打 印
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                   dateQuery:"",//日期查询
                   dateType:"",//日期类型
                   dateTypeOpt:[
                       {label: "报名日期", value: 1},
                       {label: "结业日期", value: 2},
                       {label: "交费日期", value: 3},
                       {label: "录入日期", value: 4},
                       {label: "开班日期", value: 5},
                       {label: "修改日期", value: 6}
                   ],//日期类型选项
                   condition:0,//状态
                   stateOpt:[
                       {label: "全部", value: 0},
                       {label: "开班入学", value: 1},
                       {label: "插班入学", value: 2},
                       {label: "转班入学", value: 3},
                       {label: "结业1", value: 4},
                       {label: "结业2", value: 5},
                       {label: "结业", value: 6},
                       {label: "退学", value: 7}
                   ],//状态选项
                   payment:0,//支付方式
                   paymentOpt:[
                       {label: "全部", value: 0},
                       {label: "现金", value: 1},
                       {label: "银行转账", value: 2},
                       {label: "支票", value: 3},
                       {label: "信用卡", value: 4},
                       {label: "借记卡", value: 5},
                       {label: "移动支付", value: 6},
                   ],//支付方式选项
                   subject:0,//学科类
                   subjectOpt:[
                       {label: "全部", value: 0},
                       {label: "少儿", value: 1},
                       {label: "成人", value: 2},
                   ],//学科类选项
                   search:""//搜索
                   
               },
                tableData:[],
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
                teacher:"",//授课老师
                className:"",//班级名称
                locale:"",//上课地点
                arrange:"",//课时安排
                dialog:false,//控制打印预览弹窗
                printData:{
                    top_logo:require('../../assets/logo.png'), //logo
                    number:"123456789",//编号
                    head_img:'', //头像
                    region:"深圳书画艺术学院",//所属地区
                    name:"姜青",//姓名
                    pinyin:"Jiang Qing",//拼音名
                    courseName:"成人书画院-国画研修班（周二白）",//培训课名称
                    classroom:"302教室",//上课教室
                    address:"深圳福田",//家庭住址
                    telephone:"13509621689",//手机号码
                    // arrange:"开班日期:2016-03-01,结业日期:2016-07-01",//授课安排
                    start:"2016-03-01",//开班
                    end:"2016-07-01",//结业
                    sex:"男",//性别
                    nationality:"中国",//国籍
                    nation:"汉族",//民族
                    teacher:"测试胡",//授课老师
                    birthday:"2010-03-01",//出生日期
                    zipCode:"518000",//邮编
                    phone:"0755-1234567",//电话
                    specificDate:"2021年12月28日"//时间
                }
            }
        },
        created() {
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods:{
            getList(){
                this.$request({
                    url:'/api/studenttrain/list',
                    method:'POST',
                    data:{
                       limit:this.currentLimit,
                        page:this.currentPage
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res)
                        this.tableData=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            query(){//查询
                this.currentPage=1
                this.getList()
            },
            reset(){//重置
                this.form.dateQuery=""//日期查询
                this.form.dateType=""//日期类型
                this.form.condition=0//状态
                this.form.payment=0//支付方式
                this.form.subject=0//学科类
                this.form.search=""//搜索
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addTrainNewborn"
                })
            },
            edit(index,row) { //编辑
                this.$router.push({
                    path: "editTrainNewborn",
                    query: {
                        id: row.id
                    }
                })
            },
            delt(index,row){//删除
                this.$confirm('是否删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: '/api/studenttrain/del',
                        method: 'POST',
                        data: {
                            id: row.id
                        }
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.tableData.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            Classmate(index,row){//学员
                this.$router.push({
                    path: "classmate",
                    query:{
                        id:row.id
                    }
                })
            },
            bill(){//单据汇总
                this.$router.push({
                    path: "billSummary"
                })
            },
            derive(){//导出
                
            },
            print(){//打印预览
                this.dialog=true
            },
            // 姓名排序
            fn2(a, b){
                return a.student_name.localeCompare(b.student_name)
            }
        }
        
    }
</script>

<style scoped="scoped">
    .TrainNewborn{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 刷新 新增 按钮 */
   ::v-deep .el-icon-refresh {
       font-weight: bold !important;
       color: #FFFFFF;
   }
   
   ::v-deep .el-icon-plus {
       font-weight: bold !important;
   }
   /* 打印预览 */
   .print{
       display: inline-block;
       padding-left: 10px;
       width: 94px;
       height: 30px;
       border-radius: 3px;
       vertical-align: middle;
       background: url(../../assets/print.png)8px 9px no-repeat #18BC9C;
       border: none;
       color: #FFFFFF;
       margin:0px 10px;
       cursor: pointer;
   }
   /* 导出按钮 */
   .derive{
        display: inline-block;
        width: 113px;
        height: 30px;
        padding-left: 10px;
        vertical-align: middle;
        background: url(../../assets/export.png) 6px 6px no-repeat #FF7300;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        margin-right: 10px;
        cursor: pointer;
   }
   /* 明细表 */
   .bill{
       display: inline-block;
       width: 100px;
       height: 30px;
       vertical-align: middle;
       background: #29726C;
       color: #FFFFFF;
       border-radius: 3px;
       border: none;
       cursor: pointer;
       margin-left: 10px;
   }
   /* 表格 */
   .el-table {
       margin: 20px;
   }
   
   ::v-deep .el-table__cell {
       color: #000;
       padding: 0;
       height: 29px;
   }
   .stu{
       display: inline-block;
       padding-left: 35px;
       background: url(../../assets/stud.png)10px 7px no-repeat #FF7300;
       color: #FFFFFF;
       font-size: 12px;
   }
   .txtbox{
       height: 19px;
       display: inline-block;
       margin-bottom: 18px;
       margin-right: 10px;
       font-size: 14px;
       color: #000000;
   }
   .itp{
       /* border: none; */
       height: 28px;
       outline: none;
       font-size: 14px;
       background-color: #FFFFFF;
       border: 1px solid #ccc;
   }
   /* 分页 */
   .el-pagination {
       margin: 20px;
       margin-top: 0px;
   }
   ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
       background-color: #2C3E50 !important;
   }
   
   
   /* 打印预览 */
   .TrainNewborn ::v-deep .el-dialog__body {
       padding: 25px 15px;
   }
   
   .TrainNewborn ::v-deep .el-dialog__footer {
       padding: 10px 20px 20px !important;
       text-align: center;
   }
   
   #printSignup{
       width: 850px;
       margin: 0px auto;
       color: #333;
   }
   .top {
       width: 100%;
       height: 55px;
       /* border: 1px solid red; */
       display: flex;
       justify-content: space-between;
   }
   
   .top-logo {
       width: 350px;
       height: 100%;
   }
   
   .top-logo img {
       width: 100%;
       height: 100%;
   }
   
   .top-title {
       padding-right: 50px;
       font-size: 25px;
       font-weight: bold;
       color: #333;
       letter-spacing: 5px;
   }
   
   /* 表格 */
   table {
       margin-top: 10px;
       width: 100%;
       border-collapse: collapse;
       text-align: center;
       font-size: 16px;
       font-weight: 500;
   }
   
   table td {
       height: 30px;
   }
   table td:first-child {
       width: 15%;
   }
   /* 相片 */
   .td_1 img {
       width: 100px;
       height: 130px;
       border: 1px dashed #ccc;
       margin: auto;
   }
   /* 培训课名称 */
   .td_2{
       font-weight: bold;
       font-size: 14px;
       text-align: left;
       padding-left: 5px;
   }
   /* 授课安排 */
   .td_3{
       font-weight: bold;
       font-size: 14px;
       text-align: left;
       padding-left: 5px;
   }
   .td_4{
       width: 90px;
   }
   .td_5{
       width: 155px;
   }
   table tr:last-child {
       height: 110px;
   }
   /* 备注 */
   .remarks{
       padding-left: 10px;
       text-align: left;
       font-size: 14px;
       font-weight: 500;
       font-family: Microsoft YaHei;
       color: #000000;
       line-height: 25px;
   }
   /* 底部 */
   .bottom {
       text-align: right;
   }
   
   .handle {
       margin: 20px 0px;
       font-size: 17px;
       font-weight: bold;
       color: #000000;
   }
   
   .handle input {
       border: none;
       outline: none;
   }
   .bottom_text p {
       font-size: 16px;
       color: #000000;
       padding-right: 10px;
   }
   
   .phone_date {
       margin-top: 15px;
       color: #000000;
   }
   
   .phone_date span {
       display: inline-block;
   }
   
   .phone_date span:first-child {
       padding-right: 20px;
   }
   
   .phone_date span:last-child {
       padding: 0px 10px;
   }
</style>
